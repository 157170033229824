.activatedOfferTag {
  padding: 4px 8px;
  border-radius: 100px;
  background: #f59e0b;
  width: max-content;
}

.activatedOfferTag p {
  font-weight: 600;
  color: #ffffff;
}

.errorLayoutInner {
  background-color: #ffffff;
  border-radius: 16px 16px 0 0;
  margin-top: 20px;
  padding: 0 24px 20px;
  min-height: calc(100svh - 20px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.errorLayoutLogo {
  width: 163px;
  margin-top: 64px;
}

.errorLayoutContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.errorLayoutContentTitle {
  display: flex;
  width: 100%;
  justify-content: center;
}

.errorLayoutContentTitle img {
  width: 100px;
}

.errorLayoutContentTitle svg {
  width: 70px;
  height: 70px;
  margin: auto 0;
}
@media (max-width: 640px) {
  .errorLayoutContentTitle svg {
    width: 66px;
    height: 66px;
  }
}
@media (max-width: 380px) {
  .errorLayoutContentTitle svg {
    width: 46px;
    height: 46px;
  }
}

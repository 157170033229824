.offerQualityTag {
  padding: 4px 8px;
  border-radius: 100px;
  background: #f59e0b;
  width: max-content;
  display: inline-flex;
  gap: 7px;
  align-items: center;
}

@keyframes qualityPigsBouncing {
  0% {
    transform: translate3d(0, -6px, 0);
  }
  100% {
    transform: translate3d(0, 6px, 0);
  }
}

.offerQualityTag.featured .offerQualityTagPigs > img {
  animation: qualityPigsBouncing 0.6s infinite alternate;
}

.offerQualityTag.featured .offerQualityTagPigs > img:nth-child(2) {
  animation-delay: 0.2s;
}

.offerQualityTag.featured .offerQualityTagPigs > img:nth-child(3) {
  animation-delay: 0.4s;
}

.offerQualityTag.featured .offerQualityTagPigs > img:nth-child(4) {
  animation-delay: 0.6s;
}

.offerQualityTag img {
  mix-blend-mode: luminosity;
}

.offerQualityTagPigs {
  height: 20px;
  display: flex;
}

.offerQualityTagPigs > * {
  height: 100%;
  margin-bottom: 100%;
}

.offerQualityTag img:not(:first-child) {
  margin-left: -4px;
}

.offerQualityTag p {
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: 640px) {
  @keyframes qualityPigsBouncing {
    0% {
      transform: translate3d(0, -4px, 0);
    }
    100% {
      transform: translate3d(0, 4px, 0);
    }
  }

  .offerQualityTagPigs {
    height: 16px;
  }
}

@media (max-width: 380px) {
  .offerQualityTagPigs {
    height: 14px;
  }

  .offerQualityTag img:not(:first-child) {
    margin-left: -3px;
  }
}

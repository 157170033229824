.questsListsItems {
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.questsListsItemsHeader {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
}

.questListItemContainer {
  display: flex;
  flex-direction: column;
}

.questsListItemInner {
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 20px;
  padding: 12px;
  box-shadow: 2px 4px 12px 0px #ff962b12;
  background: #ffffffcc;
}

.questsListItemInner.showTime {
  border-radius: 20px 0px 20px 20px;
}

.questsListItem {
  display: flex;
  gap: 16px;
}

.questsListItemImage {
  width: 61px;
  height: 61px;
  margin: auto 0;
  border-radius: 8px;
}

.questsListItemContent {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.questsListItemValues {
  display: flex;
  gap: 8px;
}

.questsListItemHeader {
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.questsListItemProgress {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.questsListItemProgress > span {
  padding: 0 2px;
}

.questsListItemTimer {
  position: unset;
  background: linear-gradient(0deg, #fff, #fff) padding-box,
    linear-gradient(93.2deg, #ffda6d 16.28%, #ffcb68 92.47%) border-box;
  box-shadow: none;
  border: 1px solid transparent;
  min-width: max-content;
  height: 100%;
}

.questsListItemTimer p {
  color: #636874;
}

.questsListItemTimer svg path {
  fill: #ffc700;
}

.questsListItemLocked {
  background: #000000de;
  border-radius: 20px;
  width: calc(100% + 24px);
  height: calc(100% + 24px);
  position: absolute;
  top: -12px;
  left: -12px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalImage {
  width: 80px;
  height: 80px;
  border-radius: 20px;
  margin: 25px 0 10px;
}

.modalCloseButton {
  width: 100%;
  height: max-content;
  text-align: right;
  height: 60px;
  padding: 20px 20px 0;
}

.modalCloseButton img {
  cursor: pointer;
  height: 100%;
}

@media (max-width: 640px) {
  .modalCloseButton {
    height: 50px;
  }
}

.App {
  text-align: center;
}

.container {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 24px;
}

.blured::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  z-index: 1003;

  pointer-events: none;
}

.back-arrow {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.display-none {
  display: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 24px;
}

p,
a,
li {
  font-size: 16px;
}

p img {
  height: 21px;
}

h2 img {
  height: 26px;
  transform: translateY(20%);
}

small {
  font-size: 12px;
}

.App-header {
  background-color: #282c34;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toasterCloseButton {
  cursor: pointer;
}

.connectGmailModalMore {
  font-family: var(--inter-font);
  color: #000000;
  text-decoration: none;
}

.alertIconWrap {
  width: 48px;
  height: 48px;
}

.alertIconWrap.disabled {
  opacity: 50%;
}

.alertIconWrap img {
  width: 100%;
  height: 100%;
}

.alertIconWrap svg {
  width: 26px;
  height: 26px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.priceText.coin {
  -webkit-text-fill-color: transparent;
  background: var(--coin-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  font-weight: 700;
}

.priceText.pig {
  background: var(--pig-gradient);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

.priceText.unbold {
  font-weight: 600;
}

.priceText.discount {
  position: relative;
  z-index: 1;
}

.priceText.discount::after {
  content: '';
  position: absolute;
  left: -7.5%;
  right: 0;
  top: 50%;
  height: 2px;
  background: var(--pink-to-gold-gradient);
  transform: translateY(-50%);
  border-radius: 3px;
  z-index: 2;
  width: 115%;
}

.valueGradientWrap {
  background: linear-gradient(#fff9f3, #fff9f3) padding-box, var(--pink-to-gold-gradient) border-box;
  border-radius: 20px;
  border: 1px solid transparent;
  padding: 3px 6px 3px 8px;
  width: max-content;
}

.valueGradientWrap.modal {
  background: linear-gradient(rgb(255, 251, 234), rgb(255, 251, 234)) padding-box,
    var(--pink-to-gold-gradient) border-box;
}

.alertSwipe {
  animation: alertOut 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  pointer-events: none;
}

.alertSwipe.hidden {
  display: none;
}

.slick-arrow:before {
  font-size: 40px;
}

.scroll-hidden {
  overflow: hidden;
}

@keyframes alertOut {
  0% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
  30% {
    transform: translateX(-24px) skewX(0deg) scaleX(0.95);
  }
  100% {
    transform: translateX(1500px) skewX(30deg) scaleX(1.3);
  }
}

@media (max-width: 640px) {
  h1 {
    font-size: 24px;
  }

  h2 {
    font-size: 16px;
  }

  p,
  a,
  li {
    font-size: 12px;
  }

  small {
    font-size: 10px;
  }

  p img {
    height: 18px;
  }

  h2 img {
    height: 16px;
  }
}

@media (max-width: 380px) {
  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 14px;
  }

  p,
  a,
  li {
    font-size: 10px;
  }

  small {
    font-size: 8px;
  }

  p img {
    height: 16px;
  }

  h2 img {
    height: 18px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gamesListPiggyBankChestOpenButton {
  border-radius: 14px;
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  cursor: pointer;
  width: max-content;
  margin: auto;
}

.gamesListPiggyBankChestOpenButton.disabled {
  pointer-events: none;
}

.gamesListPiggyBankChestOpenButton:hover {
  box-shadow: 2px 4px 22px 0px rgba(0, 0, 0, 0.25);
}

.gamesListPiggyBankChestOpenButtonHead {
  padding: 5px 20px;
  background: var(--Yellow-Gradient, linear-gradient(180deg, #ffcd47 0%, #eb9800 100%));
}

.gamesListPiggyBankChestOpenButtonHead img {
  height: 89px;
}

.gamesListPiggyBankChestOpenButtonFoot {
  padding: 5px 10px;
  background-color: #fff;
  display: flex;
  gap: 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.gamesListPiggyBankChestOpenButtonFoot img {
  height: 25px;
}

.offerCardContent {
  position: absolute;
  width: 100%;
  bottom: 11px;
  z-index: 2;
  right: 0;
  padding: 0 18px 0 12px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
}

.offerCardFeaturedName {
  display: flex;
  gap: 4px;
}

.offerCardInner.featured .offerCardShadow {
  background: linear-gradient(180.65deg, rgba(0, 0, 0, 0) 30%, #000000 99.44%);
}

.offerCardFooter {
  display: flex;
  justify-content: space-between;
}

.offerCardInner.default .offerCardImage {
  background-size: cover;
}

.offerCardInner.activated .offerCardShadow {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 15%, #000000 100%);
}

.offerCardActiveTag {
  position: absolute;
  top: 11px;
  left: 12px;
  z-index: 3;
}

.offerCardTimer {
  position: absolute;
  top: 11px;
  right: 12px;
  z-index: 3;
}

.offerCardUAOfferTag {
  border-radius: 100px;
  color: #ffffff;
  background: #00000080;
  padding: 4px 8px;
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 3;
}

.offerCardActiveOfferFooter {
  padding: 4px 8px;
  border-radius: 100px;
  background: #b000b4;
  width: max-content;
}

.offerCardActiveOfferFooter p {
  font-weight: 600;
  color: #ffffff;
}

.offerCardFooter .activatedOfferCardTask {
  background: rgba(255, 255, 255, 0.80);
  margin-bottom: 0;
}

.offerCardFooter .activatedOfferCardTask .valueGradientWrap {
  background: linear-gradient(#d5d9d8, #d5d9d8) padding-box, var(--pink-to-gold-gradient) border-box !important;
}

.offerCardFooter .activatedOfferCardTask .gameDetailsOfferTaskTimer {
  border: 1px solid #1f1f1f;
  background: unset;
}

.offerCardFooter .activatedOfferCardTask .gameDetailsOfferTaskTimer p {
  font-weight: 400;
}

.offerCardFooter .activatedOfferCardTask .valueGradientWrap p {
  color: #1f1f1f;
}

.offerCardFooter .activatedOfferCardTask .gameDetailsOfferTaskContent h4 {
  color: #1e293b;
  white-space: initial;
}

.offerCardFooter .genericOffer {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.offerCardFooter .genericOffer.reduceBottomMargin {
  margin-bottom: -16px;
}
.authorizationContentActionsButtons {
  display: flex;
  flex-direction: column;
}

.authorizationContent {
  border: 3px solid #5a2233;
  background: linear-gradient(0deg, #ffe3cf, #ffe3cf);
  border-radius: 16px;
  padding: 53px 20px 32px;
  margin-top: -31px;
}

@media (max-height: 840px) {
  .authorizationImage {
    height: 120px;
  }

  .authorizationContent {
    margin-top: -23px;
  }
}

@media (max-height: 750px) {
  .authorizationImage {
    display: none;
  }

  .authorizationContent {
    margin-top: 0;
  }
}

.questsListNoItemsInner {
  padding: 0 12px 12px;
}

.questsListNoItems {
  background: #fffdf9;
  border-radius: 20px;
  padding: 8px 3px 19px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.questsListNoItems img {
  height: 208px;
  width: auto;
}

.onboardingTooltipInner {
  position: fixed;
  height: 100svh;
  width: 100svw;
  left: 0;
  top: 0;
  z-index: 10001;
  overflow: auto;
}

.onboardingTooltip {
  text-align: center;
  display: flex;
  gap: 26px;
  flex-direction: column;
  max-width: 450px;
  min-width: 270px;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.onboardingTooltip .MuiTypography-title {
  color: #fb923c;
  font-family: var(--open-sans-font);
  font-weight: 700;
}

.onboardingTooltip .onboardingMoveToGiftCards {
  color: #fff;
}

.__floater__arrow {
  visibility: hidden;
  margin: 20px;
}

.gameDetailsRewardHistory {
  padding: 12px;
  background: #ffffff99;
  border: 2px solid #ffffff99;
  box-shadow: 2px 4px 12px 0px #ff962b12;
  border-radius: 14px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin: 20px 16px 0;
}

.gameDetailsRewardHistory.quests {
  background: unset;
  border: unset;
  box-shadow: unset;
  padding: 4px 16px 0;
  margin: 8px 0 0;
}

.gameDetailsRewardHistory.referFriend {
  margin: 0 0 20px;
}

.gameDetailsRewardHistoryHeader {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

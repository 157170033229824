@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

:root {
  --open-sans-font: 'Open Sans', sans-serif;
  --poppins-font: 'Poppins', sans-serif;
  --inter-font: 'Inter', sans-serif;
  --roboto-font: 'Roboto', sans-serif;
  --coin-gradient: linear-gradient(180deg, #ffcd47 0%, #eb9800 100%);
  --pig-gradient: linear-gradient(180deg, #ffafc7 0%, #f25f8b 100%);
  --pink-to-gold-gradient: linear-gradient(95deg, #ff6492 1.76%, #ffa906 97.15%);
  --white-to-white-gradient: linear-gradient(95deg, #fff 1.76%, #fff 97.15%);
}

body {
  margin: 0;
  font-family: var(--poppins-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
}

button {
  cursor: pointer;
  border: none;
  font-family: var(--inter-font);
}

button * {
  font-family: var(--inter-font);
}

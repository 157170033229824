.confirmOrderModalText {
  text-align: left;
}

.confirmOrderModalText img {
  height: 20px;
  display: inline-block;
  vertical-align: bottom;
}

.confirmOrderModalText span {
  font-weight: 600;
  display: inline-block;
}

@media (max-width: 640px) {
  .confirmOrderModalText img {
    height: 16px;
  }
}

@media (max-width: 380px) {
  .confirmOrderModalText img {
    height: 14px;
  }
}

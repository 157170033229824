.onboardingIosModal {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.onboardingIosModalContent {
  display: flex;
  gap: 9px;
}

.onboardingIosModalContent img {
  height: 37px;
  margin: auto;
}

.onboardingIosModalImage {
  width: 196px;
  margin: auto;
}

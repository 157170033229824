.gamesListPiggyBankChestFinishDivWrap {
  width: 72.5%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  display: none;
}

.gamesListPiggyBankChestFinishDivWrap.visible {
  display: flex;
}

.gamesListPiggyBankChestFinishDiv {
  position: absolute;
  bottom: 0;
}

.gamesListPiggyBankChestFinishDivChest {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gamesListPiggyBankChestOpenback {
  position: absolute;
  width: 106%;
  top: 0;
  right: -2%;
  transform: translateY(-60%);
  overflow: auto;
}

.gamesListPiggyBankChestPig {
  position: absolute;
  top: 0;
  width: 70%;
  transition: all 0.5s linear;
}

.gamesListPiggyBankChestPig.visible {
  transform: translateY(-94%);
}

.gamesListPiggyBankChestShine {
  left: 50%;
  transform: translate(-50%, -100%);
  height: 0;
  width: 0;
  position: absolute;
  overflow: hidden;
  transition: all 1s linear;
}

.gamesListPiggyBankChestShine.visible {
  height: 50vw;
  width: 100vw;
}

.gamesListPiggyBankChestShine img {
  width: 100%;
  height: 200%;
}

.gamesListPiggyBankChestOpenBottom {
  z-index: 1;
  width: 100%;
}

.gameDetailsSpecialOfferSpendingRewards {
  padding: 12px;
  background: #ffffff99;
  border: 2px solid #ffffff99;
  box-shadow: 2px 4px 12px 0px #ff962b12;
  border-radius: 14px;
  display: flex;
  gap: 12px;
  flex-direction: column;
  margin-top: 12px;
}

.gameDetailsSpecialOfferSpendingRewardsHeader {
  display: flex;
  justify-content: space-between;
}

.gameDetailsSpecialOfferSpendingRewardsHeader svg {
  cursor: pointer;
}

.gameDetailsSpecialOfferSpendingRewardsItem {
  border-radius: 20px;
  border: 1px solid #6b7280;
  padding: 3px 6px 3px 8px;
  width: max-content;
  display: inline-block;
  vertical-align: baseline;
}

.onboardingPointer {
  position: absolute;
  z-index: 2001;
  width: max-content;
  display: flex;
  flex-direction: column;
  text-align: right;
  gap: 15px;
}

.onboardingPointerArrow {
  width: 46px;
  animation: bounce-out 1s infinite ease;
  animation-delay: 0.1s;
}

@keyframes bounce-out {
  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0);
  }
}

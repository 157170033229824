.gameDetailsSpecialOfferInstallToUnlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px 16px 16px;
  gap: 9px;
}

.gameDetailsSpecialOfferInstallToUnlock > img {
  height: 48px;
  width: 48px;
}

.gameDetailsSpecialOfferInstallToUnlockContent {
  display: flex;
  flex-direction: column;
}

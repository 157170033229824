.accountBalanceRedemptionInner {
  border-radius: 16px;
  background: #ffffff;
  padding: 24px 0;
  margin-bottom: 12px;
}

.accountBalanceRedemptionInner h3 {
  margin-bottom: 24px;
}

.accountBalanceRedemptionDescription {
  background: #f0f0f0;
  border: 1px solid #929292;
  padding: 14px;
  border-radius: 16px;
}

.accountBalanceRedemptionDescription p {
  text-align: left;
  letter-spacing: -0.01px;
  color: #000000;
}

.accountBalanceRedemptionContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

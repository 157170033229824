.createAccountSliderItem img {
  width: 100%;
  max-width: 267px;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin: 0 auto;
}

.createAccountSliderItem {
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.createAccountSliderItemText {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 29px;
  margin-top: 12px;
}

.createAccountSliderItemTitle {
  margin-top: 32px;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0px;
  text-align: center;
}

.createAccountSliderDots {
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin: 0;
}

.createAccountSliderDots li.slick-active .createAccountSliderPage {
  background: #fcd34d;
}

.createAccountSliderDots li {
  width: 8px;
  height: 8px;
  margin: 0;
}

.createAccountSliderDots .createAccountSliderPage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #e5e7eb;
}

.createAccountSlider {
  margin-bottom: 57px;
}

.createAccountContentActionsButtons {
  display: flex;
  flex-direction: column;
}

@media (max-height: 800px) {
  .createAccountSliderItem img {
    max-width: unset;
    height: 20svh;
    width: unset;
  }
}

@media (max-height: 675px) {
  .createAccountSliderItem img {
    display: none;
  }

  .createAccountSliderItem h1 {
    margin-top: 0;
  }
}

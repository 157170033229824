.connectGmailTopic {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.connectGmailTopic {
  display: flex;
  flex-direction: row;
  gap: 17px;
}

.connectGmailTopics {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 30px;
  margin: 32px 0 20px;
  height: calc(100svh - 414px);
  overflow-y: auto;
}

.connectGmailTopics::-webkit-scrollbar {
  background-color: #e2e8f0;
  width: 2px;
  border-radius: 16px;
}

.connectGmailTopics::-webkit-scrollbar-thumb {
  background-color: rgb(251, 146, 60);
  border-radius: 16px;
}

.connectGmailTopicImage {
  width: 40px;
  height: 40px;
  padding: 8px;
  border-radius: 100px;
  background-color: #fff7ed;
}

.connectGmailTopicImage svg {
  width: 100%;
  height: 100%;
}

.connectGmailContent {
  margin-top: 15px;
  justify-items: center;
}

@media (max-width: 640px) {
  .connectGmailTopics {
    height: calc(100svh - 370px);
    gap: 20px;
  }
}

@media (max-width: 380px) {
  .connectGmailTopics {
    gap: 15px;
    height: calc(100svh - 358px);
    margin: 20px 0 15px;
  }
}

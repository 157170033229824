.gamesListOffersRowSlider .swiper-button-prev {
  left: -50px;
}

.gamesListOffersRowArrow {
  border-radius: 100px;
  background-color: #ffffff;
  height: 30px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
}

.gamesListOffersRowArrow svg {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gamesListOffersRowArrow.prev {
  left: -50px;
  transform: translateY(calc(-50% - 21px));
}

.gamesListOffersRowArrow.next {
  right: -13px;
  transform: translate(100%, calc(-50% - 21px)) rotate(180deg);
}

.gamesListOffersRowArrow.disabled {
  cursor: none;
  pointer-events: none;
  opacity: 0.15;
}

.gamesListOffersRowItems {
  margin-left: -10px;
  position: relative;
}

.gamesListOffersRowItem {
  padding: 2px 10px 23px;
  display: block;
}

.offerCardShadow {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50.29%, #000000 100%);
}

.offerCardContainImage {
  background-size: cover;
  position: absolute;
  z-index: 0;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  filter: blur(20px);
  -webkit-filter: blur(20px);
}

.offerCardContainImage.loaded {
  opacity: 1;
}

.offerCardImage {
  background-size: contain;
  z-index: 1;
  position: absolute;
  inset: 0px;
  top: 0;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.offerCardImage.loaded {
  opacity: 1;
}

@media (max-width: 400px) {
  .offerCardImage {
    background-size: cover;
  }
}

@media (max-width: 234px) {
  .offerCardImage {
    background-size: contain;
  }
}

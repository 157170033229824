.questsListFooter {
  box-shadow: 2px 4px 12px 0px #ff962b12;
  border-top: 1px solid transparent;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

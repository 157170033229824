.gamesLayoutInner {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 100svh;
}

.gamesLayoutContent {
  overflow: hidden;
  padding-top: 20px;
  min-height: calc(100svh - 130px);
}

.gameLibraryDescription {
  background: #ffffff;
  padding: 14px;
  border-radius: 20px;
  margin-top: 15px;
}

.gameLibraryItems {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 20px 0 80px;
}

.gameLibraryButton {
  padding: 0 32px;
}

.userSettingsInner {
  min-height: calc(100svh - 92px);
  background: #ffffff;
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 80px 16px;
}

@media (max-width: 380px) {
  .userSettingsInner {
    min-height: calc(100svh - 82px);
  }
}

.unlockAllOffers {
  width: 97%;
  padding: 12px;
  text-align: center;
  border-radius: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  font-weight: bold;
  background: linear-gradient(
    95deg,
    rgba(255, 100, 146, 0.15) 1.76%,
    rgba(255, 169, 6, 0.15) 97.15%
  );
  z-index: 3;
  border: 3px solid transparent;
  bottom: 18px;
  background-clip: padding-box;
}

.unlockAllOffers p {
  z-index: 3;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #0f172a;
}

.unlockAllOffers img {
  height: 30px;
  width: 30px;
  display: inline-block;
  vertical-align: bottom;
}

.unlockAllOffers::before {
  content: '';
  position: absolute;
  inset: 0;
  border-radius: 10px;
  padding: 2px;
  background: linear-gradient(95deg, #f77da1, #eb9900);
  mask: linear-gradient(#000 0 0) content-box, linear-gradient(#000 0 0);
  mask-composite: exclude;
}

.unlockAllOffers::after {
  content: '';
  margin-right: 2px;
  margin-left: 2px;
  position: absolute;
  inset: 0;
  opacity: 0.5;
  background: url('../../../public/images/gamesIcons.png') no-repeat;
  z-index: 1;
}

.giftCardListTitlesInner {
  background: #ffffff;
  border-radius: 16px 16px 0 0;
  padding: 16px 16px 0 16px;
  width: 100%;
}

.giftCardListInner {
  background: #ffffff;
}

.giftCardListInner .swiper {
  margin-left: 12px;
}

.giftCardsRow {
  position: relative;
}

.gameDetailsSpecialOfferInner {
  width: 100%;
  z-index: 1;
  margin-top: 17px;
  padding-bottom: 20px;
}

.gameDetailsSpecialOffer {
  border-radius: 16px;
  padding: 12px 16px 10px;
}

.gameDetailsSpecialOffer .MuiTypography-root {
  font-family: var(--poppins-font);
}

.gameDetailsSpecialOfferFooter {
  padding: 0 40px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.gameDetailsSpecialOfferHead {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
}

.gameDetailsSpecialOfferActivatedInner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  font-family: var(--poppins-font);
}

.gameDetailsSpecialOfferActivatedTag {
  background: linear-gradient(269.16deg, #3fb761 4.02%, #47d46f 94.26%);
}

.gameDetailsSpecialOfferActivatedTimer {
  position: unset;

  background: linear-gradient(0deg, #ffefc9, #ffefc9) padding-box,
    linear-gradient(93.2deg, #ffda6d 16.28%, #ffcb68 92.47%) border-box;
  box-shadow: none;
  border: 1px solid transparent;
}

.gameDetailsSpecialOfferActivatedTimer p {
  color: #475467;
}

.gameDetailsSpecialOfferActivatedTimer svg path {
  fill: #ffc700;
}

.gameDetailsSpecialOfferHeadValue {
  background: linear-gradient(0deg, #ffefc9, #ffefc9) padding-box,
    var(--pink-to-gold-gradient) border-box;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 2px 8px 4px;
  border-radius: 12px;
}

.gameDetailsSpecialOfferHeadValue p {
  font-family: var(--poppins-font);
  font-weight: 700;
  background: var(--pink-to-gold-gradient);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gameDetailsSpecialOfferInstallTask .MuiAlertTitle-root .MuiTypography-root {
  font-weight: 800;
}

.gameDetailsSpecialOfferLoginRewards {
  padding: 12px;
  background: #ffffff99;
  border: 2px solid #ffffff99;
  box-shadow: 2px 4px 12px 0px #ff962b12;
  border-radius: 14px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 12px;
}

.gameDetailsSpecialOfferLoginRewardsArrow {
  transform: rotate(-90deg);
  cursor: pointer;
  transition: transform 300ms ease-out;
  z-index: 1;
  margin: auto;
}

.gameDetailsSpecialOfferLoginRewardsArrow.expanded {
  transform: rotate(-270deg);
}

.gameDetailsSpecialOfferModal {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: left;
  overflow: hidden;
}

.gameDetailsSpecialOfferModalValue {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(95deg, #ff6492 1.76%, #ffa906 97.15%) border-box;
  border-radius: 20px;
  padding: 4px 6px;
  border: 1px solid transparent;
  width: max-content;
  display: inline-block;
  vertical-align: baseline;
}

.gameDetailsSpecialOfferPlaytimeRewardsTitle {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
}

.gameDetailsSpecialOfferPlaytimeRewardsCompleted {
  background: linear-gradient(0deg, rgb(254, 250, 231), rgb(254, 250, 231)) padding-box,
    linear-gradient(0deg, #007521, #00c939) border-box;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 2px 8px 4px;
  border-radius: 12px;
}

.gameDetailsSpecialOfferPlaytimeRewardsCompletedContent {
  width: 100%;
  text-align: center;
  margin-top: -20px;
}

.gameDetailsSpecialOfferPlaytimeRewardsCompletedContent img {
  max-width: 209px;
  width: 100%;
  top: 24px;
  position: relative;
}

@media (min-width: 640px) {
  .gameDetailsSpecialOfferPlaytimeRewardsCompletedContent img {
    max-width: 250px;
  }
}

.infoBox {
  display: flex;
  background: linear-gradient(#f1f5f9, #f1f5f9) padding-box, linear-gradient(95deg, #ff6492 1.76%, #ffa906 97.15%) border-box;
  border: 1px solid transparent;
  border-radius: 25px;
  padding: 10px 25px 25px 10px;
}

.infoText {
  padding-top: 15px;
  padding-left: 6px;
}

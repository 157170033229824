.loaderContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.loaderContainer.hidden {
  display: none;
}

.loaderContainer .loaderInner {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.loaderSpinner {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-size: 100%;
  margin: 100px auto;
  -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
  animation: sk-rotateplane 2s infinite ease-in-out;
}

.loaderInner {
  opacity: 0;
  animation: showLoaderIcon 0s linear;
  animation-fill-mode: forwards;
  transition: all 1s;
  animation-delay: 0.5s;
}

@keyframes showLoaderIcon {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  25% {
    transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-180deg);
  }
  75% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-360deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-360deg);
  }
  100% {
    transform: perspective(120px) rotateX(-360deg) rotateY(-360deg);
    -webkit-transform: perspective(120px) rotateX(-360deg) rotateY(-360deg);
  }
}

.userSettingsGeneralItemActionBox {
  display: flex;
  gap: 12px;
}

.userSettingsItem a {
  color: #27272a;
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  display: inline-block;
}

.userSettingsItem svg {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.userSettingsItem img {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.userSettingsItemClickable {
  cursor: pointer;
}

@media (max-width: 640px) {
  .userSettingsItem svg {
    height: 16px;
    width: 16px;
  }

  .userSettingsItem img {
    height: 16px;
    width: 16px;
  }

  .userSettingsItem a {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .userSettingsItem svg {
    height: 14px;
    width: 14px;
  }

  .userSettingsItem svg {
    height: 14px;
    width: 14px;
  }

  .userSettingsItem a {
    font-size: 12px;
  }
}

.appUsageHeaderImage {
  width: 163px;
  height: 32px;
  margin-top: 32px;
  margin-bottom: auto;
}

.appUsagePermissionContent {
  justify-items: center;
  margin-top: 15px;
  margin-bottom: 15;
}
.appUsageAccessImage {
  width: 75%;
}
.appUsageBoxToggle {
  position: absolute;
  right: 0;
  bottom: -7px;
  transform: translateY(100%) rotate(180deg);
  animation: toggle-bounce 1s infinite ease;
  animation-delay: 0.1s;
}

@keyframes toggle-bounce {
  50% {
    transform: translateY(calc(100% + 10px)) rotate(180deg);
  }

  100% {
    transform: translateY(calc(100%)) rotate(180deg);
  }
}
.appUsageDoItLaterButton {
  color: #a1a1aa;
}
.appUsageBoxWrapper {
  padding: 1px;
  background: linear-gradient(90.72deg, #ff6492 6.01%, #ffb517 102.09%);
  margin-bottom: 65px;
  pointer-events: none;
  cursor: none;
}

@media (max-height: 720px) {
  .appUsageAccessImage {
    height: 160px;
    width: auto;
  }
}

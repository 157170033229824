.questProgress {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-top: 2px;
}

.questProgress > span {
    padding: 0 2px;
}

.questContainer {
    display: flex;
    justify-content: space-between;
    gap: 8px;
}

.questImageTextContainer {
    display: flex;
    flex-direction: row;
    gap: 8px;
}

.questImage {
    width: 50px;
    height:50px;
    margin: auto 0;
    border-radius: 5px;
}

.textBlock {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.questAlertReward {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 10px;
}
.messageLayoutText p {
  margin: 15px 0;
  text-align: left;
}

.messageLayoutLogo {
  width: 163px;
  margin-top: 64px;
}

.messageLayoutContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-height: 100svh;
  text-align: center;
  background-color: #ffffff;
  border-radius: 20px;
}

.messageLayoutCross {
  font-weight: 700;
  font-size: 40px;
  line-height: 54px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01px;
  color: #000000;
  position: absolute;
  right: 24px;
  top: 22px;
  height: 62px;
  width: 42px;
  justify-content: center;
}

.messageLayoutCross:active {
  color: #505050;
}

.messageLayoutImage {
  height: 226px;
}

.messageLayoutButton {
  width: 100%;
}

.messageLayoutBackArrow {
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
}

.messageLayoutBackArrow svg {
  height: 20px;
  width: 20px;
}

@media (max-height: 650px) {
  .messageLayoutImage {
    display: none;
  }
}

.gameDetailsOfferTask {
  margin-bottom: 20px;
  box-shadow: -2px -4px 12px 0px rgba(255, 169, 6, 0.10), 2px 4px 12px 0px rgba(255, 169, 6, 0.10) !important;
  border-radius: 20px !important;
  border: 2px solid white !important;
  width: 100%;
  margin-bottom: 16px;
}

.gameDetailsOfferTask.earn {
  background: linear-gradient(0deg, rgb(255, 247, 234), rgb(255, 247, 234));
  border: 2px solid #fff;
  margin-bottom: 0;
}

.gameDetailsOfferTask.earn .gameDetailsOfferTaskValue {
  background: linear-gradient(0deg, rgba(255, 247, 234), rgba(255, 247, 234)) padding-box,
    var(--pink-to-gold-gradient) border-box;
}

.gameDetailsOfferTask.active.earn {
  background: linear-gradient(0deg, rgb(255, 247, 234), rgb(255, 247, 234)) padding-box,
    var(--pink-to-gold-gradient) border-box;
  border: 2px solid transparent;
}

.gameDetailsOfferTask.active {
  background: linear-gradient(#fff4de, #fff4de) padding-box, var(--pink-to-gold-gradient) border-box;
  border: 2px solid transparent;
}

.gameDetailsOfferTask.disabled .MuiTypography-root {
  color: #94a3b8;
}

.gameDetailsOfferTask.first .MuiTypography-root {
  color: #334155;
}

.gameDetailsOfferTask.disabled {
  background: linear-gradient(0deg, rgba(255, 247, 234), rgba(255, 247, 234));
  border: 2px solid #fff;
}

.gameDetailsOfferTask.disabled .valueGradientWrap {
  background: #fffbf0;
  border: 1px solid #94a3b8;
}

.gameDetailsOfferTask.disabled .valueGradientWrap img {
  opacity: 0.5;
}

.gameDetailsOfferTask.first .valueGradientWrap {
  background: #fffbf0;
  border: 1px solid #334155;
}

.gameDetailsOfferTask.first .valueGradientWrap img {
  opacity: 0.5;
}

.gameDetailsOfferTaskContent {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.gameDetailsOfferTaskActionTimerInner {
  display: flex;
  gap: 10px;
}

.gameDetailsOfferTaskActionTimer {
  width: max-content;
  position: unset;
  text-align: center;
}

.gameDetailsOfferTaskItems {
  display: flex;
  gap: 8px;
}

.gameDetailsOfferTaskTimer {
  background: linear-gradient(94deg, rgb(255, 250, 234), rgb(255, 250, 234)) padding-box,
    linear-gradient(93.2deg, #ffda6d 16.28%, #ffcb68 92.47%) border-box;
  margin: auto;
  border: 1px solid transparent;
  height: max-content;
  border-radius: 72px;
  padding: 2px 6px;
  width: max-content;
  overflow: hidden;
}

.gameDetailsOfferTaskTimer p {
  font-weight: 500;
}

.gameDetailsOfferTaskValues {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.gameDetailsOfferTaskValue {
  background: linear-gradient(#FFF6E5, #FFF6E5) padding-box, var(--pink-to-gold-gradient) border-box !important;  
}

/* Material UI css */
.MuiAlert-icon img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

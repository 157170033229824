.onboardingStepper {
  display: flex;
  gap: 12px;
  justify-content: center;
  margin: 11px 0 20px;
}

.onboardingStepperItem {
  width: 12px;
  height: 12px;
  background: #e2e8f0;
  border-radius: 100px;
}
.onboardingStepperItem.active {
  background: #ffa702;
}

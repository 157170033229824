.accountBalanceRedemptionDetailsImage {
  height: 64px;
  width: 64px;
  object-fit: cover;
  border-radius: 8px;
}

.accountBalanceRedemptionDetailsLink {
  color: #f59e0b;
}

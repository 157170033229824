.gameDetailsOfferInner {
  flex: 1;
  width: 100%;
  z-index: 1;
  padding: 14px 14px 38px 18px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 23px;
  background: #ffffff99;
  border: 2px solid #ffffff99;
  box-shadow: 2px 4px 12px 0px #ff962b12;
}

.gameDetailsOfferContainer {
  padding: 17px 16px 0;
}

.gameDetailsOffer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gameDetailsOfferContent {
  background: linear-gradient(0deg, rgb(255, 247, 234), rgb(255, 247, 234));
  border: 2px solid #fff;
  border-radius: 12px;
  padding: 12px;
}

.gameDetailsOfferText {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
  font-weight: 400;
  letter-spacing: -0.01px;
  color: #2c2c2c;
}

.gameDetailsOfferText strong {
  font-weight: 700;
}

.gamesHeaderLogo {
  width: 124px;
  height: 24px;
  margin: auto 0;
  cursor: pointer;
}

.gamesHeader {
  background-color: white;
  box-shadow: 0px 4px 16px 0px #00143114;
  top: 0;
  left: 0;
  z-index: 1002;
  position: sticky;
  width: 100%;
}

.gamesHeaderActions {
  display: flex;
  gap: 12px;
}

.gamesHeaderInputs {
  display: flex;
  gap: 12px;
}

.gamesHeaderUserIcon {
  cursor: pointer;
  width: 28px;
  height: 28px;
  border-radius: 100px;
  background: #f3f4f6;
  margin: auto 0;
  position: relative;
}

.gamesHeaderUserIcon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 430px) {
  .gamesHeaderLogo {
    width: 100%;
    max-width: 80px;
    height: auto;
  }
}

@media (max-width: 350px) {
  .gamesHeaderLogo {
    display: none;
  }

  .gamesHeaderActions {
    justify-content: space-between;
    width: 100%;
  }
}

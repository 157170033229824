.progressBarContainer {
  height: 14px;
  width: 100%;
  background-color: #fff;
  border-radius: 20px;
  padding: 2px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.progressBarFiller {
  height: 100%;
  border-radius: 20px;
  text-align: right;
  transition: width 1s ease-in-out;
  min-width: 10px;
}

.progressBarFiller.gold {
  background: linear-gradient(270deg, #ffc81f 4.52%, #ffb01f 92.66%);
  box-shadow: 0px 4px 12px 0px #ff962b1f;
}

.progressBarFiller.pink {
  background: linear-gradient(270deg, #ff6492 4.52%, #ff7577 92.66%);
  box-shadow: 0px 4px 12px 0px #ff962b1f;
}

.progressBarFiller.completed {
  background: linear-gradient(270deg, #a4fea4 4.52%, #12e292 92.66%);
  box-shadow: 0px 4px 12px 0px #ff962b1f;
}

.gameDetailsSpecialOfferRewardProgress {
  padding: 12px;
  background: #ffffff99;
  border: 2px solid #ffffff99;
  box-shadow: 2px 4px 12px 0px #ff962b12;
  border-radius: 14px;
  display: flex;
  gap: 16px;
  flex-direction: column;
}

.gameDetailsSpecialOfferRewardProgressItem {
  display: flex;
  gap: 16px;
}

.gameDetailsSpecialOfferRewardProgressItem img {
  height: 48px;
}

.gameDetailsSpecialOfferRewardProgressItemContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-top: 4px;
}

@media (max-width: 640px) {
  .gameDetailsSpecialOfferRewardProgressItem img {
    height: 44px;
  }
}

@media (max-width: 380px) {
  .gameDetailsSpecialOfferRewardProgressItem img {
    height: 42px;
  }
}

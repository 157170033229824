.discountTag {
    padding: 4px 8px;
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.9);
    width: max-content;
}

.discountTag p {
    font-family: var(--poppins-font);
    font-weight: 700;
    background: var(--pink-to-gold-gradient);
    background-clip: text;
    -webkit-text-fill-color: transparent;
}


.gamesListClaimRewardsImage {
  border-radius: 8px;
  width: 48px;
  height: 48px;
}

.gamesListClaimRewardsArrowWrap {
  width: 24px;
  height: 24px;
  border-radius: 100px;
  background: #fff7ed;
  position: relative;
  cursor: pointer;
}

.gamesListClaimRewardsArrowWrap svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.gamesListClaimRewardsArrow {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) rotate(180deg);
  position: absolute;
  cursor: pointer;
}

@media (max-width: 640px) {
  .gamesListClaimRewardsImage {
    width: auto;
    height: 49px;    
    padding: 2px;
  }
}

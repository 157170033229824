.gamesListFeaturedGameCardInner .slick-dots {
  bottom: 16px;
}

.offerCardSlider .swiper-pagination {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  padding-left: 0;
  margin: 0;
}

.offerCardSlider .offerCardSliderBullet {
  width: 5px;
  height: 5px;
  margin: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  border: 0.2px solid #d9d9d9;
  opacity: 1;
}

.offerCardSlider .swiper-pagination .offerCardSliderBullet.active {
  background: #d9d9d9;
}

.offerCardSliderImage {
  position: relative;
  height: 175px;
}

@media (min-width: 640px) {
  .offerCardSlider .swiper-pagination {
    gap: 4px;
  }

  .offerCardSlider .swiper-pagination .offerCardSliderBullet {
    width: 8px;
    height: 8px;
    border: 0.5px solid #d9d9d9;
  }
}

@media (max-width: 639px) {
  .offerCardSlider .swiper-pagination .offerCardSliderBullet {
    width: 5px;
    height: 5px;
  }
}

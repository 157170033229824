.eligibilityGateContent {
    margin-top: 15px;
}

.eligibilityGateForm {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 24px;
}

.noteText {
    margin-top: 16px;
    text-align: left;
}
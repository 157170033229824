.howItWorksInner {
  background-color: #ffffff;
  padding: 24px 16px;
  border-radius: 16px 16px 0px 0px;
  min-height: calc(100svh - 24px);
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
}

.howItWorksHeader {
  background-color: #fff7ed;
  padding: 24px 16px 0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
  position: relative;
  overflow: hidden;
}

.howItWorksHeaderImage {
  height: 326px;
  max-width: 293px;
  width: 100%;
  object-fit: cover;
  object-position: top;
  z-index: 1;
  border: 4px solid #e2e8f0;
  border-bottom: unset;
  border-radius: 20px 20px 0 0;
}

.howItWorksHeaderCoin {
  position: absolute;
}

.howItWorksHeaderCoin:nth-child(1) {
  width: 61px;
  height: 61px;
  top: -15px;
  right: -8px;
}

.howItWorksHeaderCoin:nth-child(2) {
  width: 52px;
  height: 52px;
  top: 94px;
  left: -8px;
  filter: blur(3px);
}

.howItWorksEarn {
  background: #fffbeb;
  padding: 24px 52px;
  border-radius: 20px;
}

.howItWorksEarnInputInner {
  height: max-content;
  border-radius: 100px;
  padding: 1px;
  margin: auto 0;
  position: relative;
  margin-right: 15px;
}

.howItWorksEarnInputImage {
  position: absolute;
  height: 45px;
  right: -15px;
  top: -7px;
}

.howItWorksEarnInputInner.coin {
  background: var(--coin-gradient);
}

.howItWorksEarnInputInner.pig {
  background: var(--pig-gradient);
}

.howItWorksEarnInput {
  border-radius: 100px;
  padding: 5px 25px 5px 7px;
  display: flex;
  justify-content: space-between;
  gap: 9px;
  min-width: 64px;
}

.howItWorksEarnInput.coin {
  background: linear-gradient(0deg, #ffedd5, #ffedd5);
  padding: 5px 30px 5px 7px;
}

.howItWorksEarnInput.pig {
  background: linear-gradient(0deg, #fce7f3, #fce7f3);
  padding: 5px 35px 5px 7px;
}

.howItWorksInner .howItWorksText {
  font-weight: 400;
}

.howItWorksText.rate {
  margin: 55px 0 8px;
}

.howItWorksLimited {
  background: #fff7ed;
  padding: 24px 52px;
  border-radius: 20px;
  text-align: center;
}

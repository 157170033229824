.earnGiftCardsValue {
  display: inline-block;
  vertical-align: baseline;
  background: linear-gradient(#ffffff, #ffffff) padding-box, var(--pink-to-gold-gradient) border-box;
}

.earnGiftCardsValue.balance {
  background: linear-gradient(rgb(255, 238, 197), rgb(255, 238, 197)) padding-box,
    var(--pink-to-gold-gradient) border-box;
}

.detailsHeaderTitle {
  display: flex;
  gap: 8px;
  margin: auto 0;
}

.detailsHeaderTitle svg {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .detailsHeaderTitle svg {
    height: 24px;
    width: 24px;
  }
}

@media (max-width: 380px) {
  .detailsHeaderTitle svg {
    height: 20px;
    width: 20px;
  }
}

.priceText {
  display: flex;
  align-items: center;
  gap: 3px;
  text-align: right;
  letter-spacing: -0.01px;
  text-transform: capitalize;
  margin: 0;
  width: max-content;
}

.priceText.red {
  color: red;
  font-weight: 700;
}

.priceText-container {
  display: flex;
  flex-direction: column;
}

.giftCardDetailsInner {
  background-color: #ffffff;
  height: 100%;
  display: flex;
  min-height: calc(100svh - 92px);
  flex-direction: column;
  justify-content: space-between;
  padding: 0 24px;
  border-radius: 20px 20px 0 0;
}

.pageInner .giftCardDetailsContainer {
  padding: 0;
}

.giftCardDetailsContent {
  text-align: center;
}

.giftCardDetailsImage {
  margin-top: 20px;
  width: 100%;
  max-width: 300px;
}

.giftCardDetailsInner h2 {
  margin-top: 31px;
}

.giftCardDetailsInner p {
  text-transform: capitalize;
  color: #505050;
  margin-top: 30px;
  text-align: left;
}

.giftCardDetailsSubmitButtonText {
  display: flex;
  gap: 3px;
}

.giftCardDetailsSubmitButtonText .MuiTypography-root {
  -webkit-text-stroke-color: #0000003e;
  -webkit-text-stroke-width: 1px;
}

@media (max-width: 380px) {
  .giftCardDetailsInner {
    min-height: calc(100svh - 82px);
  }
}

.referralLandingInner {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.referralLandingHeader {
  padding: 28px 50px;
  text-align: left;
}

.referralLandingHeader img {
  height: 44px;
}

.referralLandingEarn {
  padding: 105px 0;
  background: linear-gradient(0deg, rgba(255, 0, 0, 0.28), rgba(255, 0, 0, 0.28)),
    linear-gradient(174.55deg, #ffc52d 16.05%, #ffb110 70.59%, #ff6492 95.39%);
}

.referralLandingJoin {
  padding: 53px 0 64px;
}

.referralLandingHow {
  padding: 53px 0 76px;
  background-size: cover;
  background-repeat: no-repeat;
}

.referralLandingHowItems {
  display: flex;
  gap: 64px;
}

.referralLandingHowItem {
  display: flex;
  gap: 9px;
}

.referralLandingHowItemNumber {
  min-width: 32px;
  max-width: 32px;
  height: 32px;
  background: #ff8210;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.referralLandingHowItemText {
  text-align: left;
  margin-top: 3px;
  max-width: 300px;
}

.referralLandingBonuses {
  padding: 90px 0 104px;
}

.referralLandingBonusesFormula {
  display: flex;
  gap: 25px;
  margin-top: 20px;
}

.referralLandingBonusesInputWrap.coin {
  background: var(--coin-gradient);
}

.referralLandingBonusesInputWrap.pig {
  background: var(--pig-gradient);
}

.referralLandingBonusesInputImage {
  position: absolute;
  height: 80px;
  right: -27px;
  top: -7px;
}

.referralLandingBonusesInputInner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: max-content;
}

.referralLandingBonusesInputInner > p {
  max-width: 235px;
}

.referralLandingBonusesInputWrap {
  height: max-content;
  border-radius: 100px;
  padding: 1px;
  position: relative;
  margin-right: 15px;
  width: max-content;
  margin: auto;
}

.referralLandingBonusesInput {
  border-radius: 100px;
  display: flex;
  justify-content: space-between;
  gap: 9px;
  min-width: 64px;
  width: max-content;
}

.referralLandingBonusesInput.coin {
  background: linear-gradient(0deg, #ffedd5, #ffedd5);
  padding: 5px 50px 5px 13px;
}

.referralLandingBonusesInput.pig {
  background: linear-gradient(0deg, #fce7f3, #fce7f3);
  padding: 5px 60px 5px 13px;
}

.referralLandingFooter {
  padding: 70px 0 50px 21px;
  background: #525252;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.referralLandingFooterLinks {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.referralLandingFooter img {
  width: 174px;
}

@media (max-width: 900px) {
  .referralLandingHowItems {
    flex-direction: column;
    gap: 18px;
    padding: 0 28px 0 59px;
  }

  .referralLandingHowItem {
    margin: auto;
  }
}

@media (max-width: 640px) {
  .referrallandingheader {
    padding: 19px 21px;
  }

  .referralLandingHeader img {
    height: 24px;
  }

  .referralLandingEarn {
    padding: 72px 0;
  }

  .referralLandingJoin {
    padding: 54px 0 57px;
  }

  .referralLandingHow {
    padding: 55px 0 65px;
  }

  .referralLandingBonuses {
    padding: 58px 0 89px;
  }

  .referralLandingBonusesInputImage {
    height: 69px;
  }

  .referralLandingBonusesFormula {
    flex-direction: column;
    gap: 15px;
    align-items: center;
    text-align: center;
    margin-top: 0;
  }
}

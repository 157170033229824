.gamesListFeaturedGamesContainer {
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 40px;
}

.gamesListFeaturedGamesInner {
  background: #ffffff;
  border-radius: 16px 16px 0 0;
  padding: 16px 0 0 16px;
  width: 100%;
}

.gameListFeaturedGamesHead {
  padding-right: 16px;
}

.gamesListFeaturedGamesTitle {
  letter-spacing: -0.009999999776482582px;
  text-align: left;
  color: #2c2c2c;
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.gamesListFeaturedGamesTitle.disabled {
  cursor: unset;
}

.gamesListFeaturedGamesText {
  letter-spacing: -0.009999999776482582px;
  text-align: left;
  color: #2c2c2c;
  margin-top: 6px;
}

.gamesListOffersDescription {
  background: #ffffff;
  padding: 14px;
  border-radius: 16px;
  margin: 15px 0 90px;
}

.gamesListLink {
  font-style: italic;
  color: #000000;
}

.gamesListFeaturedGamesTitle svg {
  transform: rotate(270deg);
  transition: all 0.5s linear;
}

.gamesListFeaturedGamesTitle.hidden svg {
  transform: rotate(450deg);
}

.gamesListNotActivatedOffersWrap {
  transition: all 1s linear;
  overflow: hidden;
  padding-bottom: 4px;
}

.gamesListFeaturedOffers {
  padding: 0 16px 23px 0;
}

.gamesListTitleRarityInfoContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.gamesListInfoButton svg {
  /* .gamesListFeaturedGamesTitle & .gamesListFeaturedGamesTitle.hidden animates all svg descendants.  This prevents the info modal button from animating.  */
  transform: none !important;
}

.gamesListInfoButton {
  display: inline-flex;
  align-items: center;
  background: transparent;
  padding: 0;
}

.gamesListPiggyBankChestModal .MuiPaper-root {
  overflow-y: unset;
}

.gamesListPiggyBankChestModal * {
  text-align: center;
}

.gamesListPiggyBankChestClose {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 2;
}

.gamesListPiggyBankChestContent {
  background: #fff;
  border-radius: 20px;
  padding: 30px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 15px;
}

.gamesListPiggyBankChestContentWrap {
  top: -20px;
  position: relative;
  width: 100%;
}

.gamesListPiggyBankChestFinishFoot {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.gamesListPiggyBankChestInputInner {
  margin: auto;
  width: max-content;
}

.gamesListPiggyBankChestInputInner h1 {
  line-height: 80%;
}

.gamesListPiggyBankChestInputInner .pointInputImage {
  height: 50px;
}

.gamesListPiggyBankChestInputInner .pointInput {
  padding: 5px 40px 5px 7px;
}

@media (max-width: 640px) {
  .gamesListPiggyBankChestInputInner .pointInputImage {
    height: 42px;
  }
}

.timerInput {
  border: 1px solid #f59e0b;
  border-radius: 100px;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  gap: 9px;
  width: max-content;
  background-color: white;
  margin: auto;
}

.timerInput h4 {
  font-weight: 500;
}

.timerInput svg {
  height: 22px;
  width: 22px;
}

@media (max-width: 640px) {
  .timerInput svg {
    height: 18px;
    width: 18px;
  }
}

@media (max-width: 380px) {
  .timerInput svg {
    height: 16px;
    width: 16px;
  }
}

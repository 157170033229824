.timer {
  border-radius: 12px;
  text-align: right;
  color: #ffffff;
  background: #00000080;
  padding: 4px 8px;
}

.timer.active {
  background: #f59e0b;
}

.timer.active.myGamesOffer {
  background: #00000080;
}

.timer.expired {
  background: #dc2626;
  color: #ffffff;
}

.timer.expired p {
  color: #ffffff;
}

.timer svg {
  display: inline;
  vertical-align: bottom;
}

.timer svg path {
  fill: #ffffff;
}

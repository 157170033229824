.videoInner {
  position: relative;
  overflow: hidden;
  height: 269px;
  width: 100%;
  background-size: cover;
  background-position: top;
  clip-path: 0 0;
  transform: translatez(0);
  cursor: pointer;
  background: #000000;
}

.videoInner * {
  cursor: pointer;
}

.video {
  transform: translatez(0);
  position: absolute;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
  width: auto;
  min-width: 100%;
  height: 100%;
  min-height: 269px;
  pointer-events: none;
}

.video.buttons-visible {
  pointer-events: unset;
  cursor: pointer;
}

.video.vertical {
  width: 100%;
  height: auto;
}

.videoFilter {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
  height: calc(100% - 10px);
  width: 100%;
  position: absolute;
  z-index: 1;
}

.videoFilter.hidden {
  opacity: 0;
  transition: opacity 0.5s linear;
}

.videoFilter svg {
  height: 65px;
  width: 65px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 480px) {
  .video {
    width: 100%;
    height: auto;
  }
}

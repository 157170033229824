.gamesListPiggyBankChestProgress {
  position: relative;
  z-index: 1;
  transition: all 0.05s linear;
  opacity: 1;
}

.gamesListPiggyBankChestProgress.hidden {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.gamesListPiggyBankChestProgressShine {
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.5s, height 0.5s;
  z-index: 1;
  opacity: 0.95;
}

.gamesListPiggyBankChestProgressShine.visible {
  width: 1500px;
  height: auto;
  animation: progressShineSpin 20s linear infinite;
}

.gamesListPiggyBankChestProgressImage {
  padding-top: 40px;
  width: 72.5%;
  margin: auto;
  margin-bottom: -36px;
  z-index: 10;
}

.gamesListPiggyBankChestProgressImage.progress {
  animation: chestJiggle 0.25s linear infinite;
}

@keyframes chestJiggle {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  50% {
    transform: rotate(0eg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes progressShineSpin {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

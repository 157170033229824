.gamesFooterWrap {
  padding-top: 2px;
  height: 70px;
  position: sticky;
  bottom: 0;
  width: 100%;
  z-index: 1003;
  border-radius: 16px 16px 0px 0px;
}

.gamesFooter {
  background-color: #ffffff;
  width: 100%;
  position: relative;
  border-radius: 16px 16px 0px 0px;
}

.gamesFooterInner {
  display: flex;
  justify-content: space-between;
}

.gamesFooterContainer {
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.gamesFooterLink {
  height: 68px;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  gap: 6px;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
}

.gamesFooterLinkText {
  color: #a1a1aa;
}

.gamesFooterLinkIconInner {
  background-color: #929292;
  width: max-content;
  height: max-content;
}

.gamesFooterLink.active .gamesFooterLinkText {
  color: #f5b426;
}

.gameDetailsOfferParentContainer {
  display: flex;
  flex-direction: column;
}

.offer-limited-time {
  width: auto;
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 20px 20px 0px 0px;
  background: var(--pink-to-gold, linear-gradient(95deg, #ff6492 1.76%, #ffa906 97.15%));
  color: white;
  padding: 2px 20px;
  font-style: italic;
}

.offer-limited-time img {
  width: 18px;
  height: 18px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7307%) hue-rotate(76deg) brightness(108%) contrast(100%);
}

.gameDetailsOffer {
  width: 100%;
  position: relative;
  background: linear-gradient(0deg, rgba(255, 247, 234), rgba(255, 247, 234)) !important;
  box-shadow: -2px -4px 12px 0px rgba(255, 169, 6, 0.1), 2px 4px 12px 0px rgba(255, 169, 6, 0.1) !important;
  border-radius: 20px !important;
  border: 2px solid white !important;
  margin-bottom: 16px;
  overflow: hidden;
  padding: 12px !important;
  opacity: 0.9;
}

.gameDetailsOffer.showTime {
  border-radius: 20px 0px 20px 20px !important;
}

.gameDetailsOffer .progress {
  position: absolute;
  inset: 0;
  height: 100% !important;
  background: linear-gradient(90deg,
      #ffa906 0%,
      rgba(255, 169, 6, 0.4) 100%,
      rgba(255, 255, 255, 0.4) 100%);
  box-shadow: 2px 4px 12px 0px rgba(255, 150, 43, 0.07);
  z-index: 0;
}

.gameDetailsOffer .container {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  z-index: 1;
  gap: 12px;
  border: none !important;
  width: 100% !important;
  padding: 0px;
}

.gameDetailsOffer .overlay {
  position: absolute;
  inset: 0;
  background: #fff3;
  z-index: 2;
}

.gameDetailsOffer .locked {
  opacity: 0.7;
}

.gameDetailsOffer .locked .lock-container {
  width: 48px;
  height: 48px;
}

.gameDetailsOffer .locked .lock-container img {
  width: 100%;
  height: 100%;
}

.gameDetailsOffer .container img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}

.gameDetailsOffer .container .completed-container {
  width: 48px;
  height: 48px;
}

.gameDetailsOffer .container .completed-container img {
  width: 100%;
  height: 100%;
}

.gameDetailsOffer .container .content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.gameDetailsOffer .container .content .title {
  color: #1f1f1f;
  font-weight: 500;
  text-wrap: wrap;
}

.gameDetailsOffer .container .content .sub-title span {
  display: flex;
  gap: 4px;
  align-items: center;
  color: #636874;
  text-wrap: wrap;
}

.gameDetailsOffer .container .content .sub-title img {
  width: 14px;
  height: 14px;
}

.gameDetailsOffer .container .content .completed {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.gameDetailsOffer .container .content .completed img {
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(32%) sepia(98%) saturate(1997%) hue-rotate(146deg) brightness(98%) contrast(98%);
}

.gameDetailsOffer .container .rewards {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
}

.gameDetailsOffer .container .actionButton {
  width: max-content;
  padding: 6px 16px;
}

.offer-limited-time {
  display: flex;
  gap: 4px;
  border-radius: 15px 15px 0px 0px;
  background: var(--pink-to-gold, linear-gradient(95deg, #ff6492 1.76%, #ffa906 97.15%));
  color: white;
  font-style: italic;
  font-size: 12px;
}

.offer-limited-time img {
  width: 14px;
  height: 14px;
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7307%) hue-rotate(76deg) brightness(108%) contrast(100%);
}

.offer-limited-time strong {
  min-width: 52px;
}

@media screen and (min-width: 420px) {
  .offer-limited-time {
    font-size: 14px;
  }

  .offer-limited-time img {
    width: 16px;
    height: 16px;
  }

  .offer-limited-time strong {
    min-width: 62px;
  }
}

@media screen and (min-width: 596px) {
  .offer-limited-time {
    font-size: 16px;
  }

  .offer-limited-time img {
    width: 18px;
    height: 18px;
  }

  .offer-limited-time strong {
    min-width: 72px;
  }

  .gameDetailsOffer .container .content .completed img {
    width: 24px;
    height: 24px;
  }
}
.gameDetailsBackArrowInner {
  position: sticky;
  top: 12px;
  width: max-content;
  z-index: 3;
  padding-left: 12px;
}

.gameDetailsBackArrowInner.disabled .gameDetailsBackArrow {
  cursor: default;
}

.gameDetailsBackArrowInner.disabled .back-arrow {
  cursor: default;
}

.gameDetailsBackArrow {
  padding: 8px;
  border-radius: 100px;
  background-color: #ffffff;
  height: 36px;
  width: 36px;
  cursor: pointer;
}

.gameDetailsBackArrow svg {
  height: 20px;
  width: 20px;
}

.gamesListPiggyBankChestModalTooltip .MuiPaper-root {
  border-radius: 16px;
  background: #fff5d2;
  box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 20px 15px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  text-align: center;
}

.gamesListPiggyBankChestModalTooltipTable {
  border-spacing: 15px 0;
  table-layout: fixed;
  width: 100%;
}

.gamesListPiggyBankChestModalTooltipTable td {
  width: 50%;
}

.gamesListPiggyBankChestModalTooltipTable thead p {
  font-weight: 700;
}

.reward-points {
    width: max-content !important;
    color: #0F172A;
    padding: 3px 8px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 4px;
    position: relative;
    z-index: 0;
}

.reward-points img {
    width: 14px !important;
    height: 14px !important;
}

.reward-points:before {
    content: "";
    position: absolute;
    z-index: -1;
    inset: 0;
    /* the border thickness */
    padding: 1px;
    border-radius: 20px;
    background: linear-gradient(to right, #ff6492, #ffa906);
    mask:
        linear-gradient(#000 0 0) exclude,
        linear-gradient(#000 0 0) content-box;
}

.reward-points.locked:before {
    background: linear-gradient(to right, #94a3b8, #94a3b8);
}

@media screen and (min-width: 380px) {
    .reward-points img {
        width: 16px !important;
        height: 16px !important;
    }
}

@media screen and (min-width: 640px) {
    .reward-points img {
        width: 20px !important;
        height: 20px !important;
    }
}
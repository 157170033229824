.detailsLayout.hide {
  animation: slideOff 0.5s forwards;
}

.detailsLayout {
  padding-top: 38px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100svh;
}

.detailsLayoutContent {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

@keyframes slideOff {
  0% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
  100% {
    transform: translateX(1500px);
  }
}

.accountBalanceInner {
  height: 100%;
  min-height: 100svh;
  width: 100%;
  background-size: 100% 100%;
}

.accountBalanceTitle {
  display: flex;
  gap: 8px;
  margin: auto 0;
}

.accountBalanceTitle svg {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.accountBalanceHowItWorks {
  padding: 6px;
  background: #ffffff;
  border-radius: 100px;
  width: 42px;
  height: 42px;
}

.accountBalanceHowItWorks svg {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.accountBalanceItem {
  margin-top: 12px;
}

.accountBalanceExplainText {
  padding: 12px;
}

.accountBalanceExplain {
  padding: 12px 0;
}

.accountBalanceItem img {
  height: 30px;
  display: inline-block;
  vertical-align: top;
}

.accountBalanceValueWrap {
  display: inline-block;
  vertical-align: baseline;
  background: linear-gradient(rgb(255, 238, 197), rgb(255, 238, 197)) padding-box,
    var(--pink-to-gold-gradient) border-box;
}

.accountBalanceValueWrap p {
  height: max-content;
}

.accountBalanceGetResultText {
  display: flex;
  gap: 5px;
  align-items: center;
}

.accountBalanceGetResultTextPlus {
  font-size: 31px;
}

@media (max-width: 640px) {
  .accountBalanceTitle svg {
    height: 24px;
    width: 24px;
  }

  .accountBalanceHowItWorks svg {
    height: 24px;
    width: 24px;
  }

  .accountBalanceHowItWorks {
    width: 36px;
    height: 36px;
  }

  .accountBalanceItem img {
    height: 24px;
  }

  .accountBalanceGetResultTextPlus {
    font-size: 25px;
  }
}

@media (max-width: 380px) {
  .accountBalanceTitle svg {
    height: 20px;
    width: 20px;
  }

  .accountBalanceHowItWorks svg {
    height: 20px;
    width: 20px;
  }

  .accountBalanceHowItWorks {
    width: 32px;
    height: 32px;
  }

  .accountBalanceItem img {
    height: 20px;
  }

  .accountBalanceGetResultTextPlus {
    font-size: 23px;
  }
}

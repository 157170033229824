.onboardingLayout {
  height: calc(100svh + 200px);
  width: 200svw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0);
  z-index: 10000;
  mix-blend-mode: hard-light;
  opacity: 0.87;
  transition: opacity 0.25 linear;
}

.onboardingLayout.hidden {
  opacity: 0;
}

.onboardingActivateOfferContent h1 {
  color: #fff;
}

.onboardingActivateOfferContent {
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.onboardingActivateOfferArrows {
  display: flex;
  width: 100%;
  gap: 148px;
  justify-content: space-around;
}

.onboardingActivateOfferArrows {
  transform: rotate(180deg);
}

.onboardingMoveToGiftCards {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 48px;
  font-weight: 700;
  font-family: var(--open-sans-font);
}

.onboardingMoveToGiftCardsCoin {
  width: 62px;
  height: 67px;
}

.onboardingMoveToGiftCardsPig {
  width: 63px;
  height: 61px;
}

.onboardingMoveToGiftCardsGiftCard {
  width: 98px;
  height: 98px;
}

@media (max-width: 400px) {
  .onboardingMoveToGiftCards {
    font-size: 40px;
  }

  .onboardingMoveToGiftCardsCoin {
    width: 37px;
    height: 40px;
  }

  .onboardingMoveToGiftCardsPig {
    width: 42px;
    height: 40px;
  }

  .onboardingMoveToGiftCardsGiftCard {
    width: 78px;
    height: 78px;
  }
}

@media (max-width: 300px) {
  .onboardingActivateOfferArrows {
    gap: 80px;
  }
}

/* Modal Container */
.rateAppModal .MuiPaper-root {
    overflow-y: unset;
}

/* Center text in modal */
.rateAppModal * {
    text-align: center;
}

/* Close Button */
.rateAppModalClose {
    position: absolute;
    right: 0;
    top: -5%;
    cursor: pointer;
    z-index: 2;
}

/* Top Section with Star & Sunburst */
.rateAppModalTopSection {
    position: relative;
}

/* Sunburst Background Effect */
.rateAppModalTopSunBurst {
    position: absolute;
    width: 400px;
    top: -105%;
    right: -10%;
    z-index: -1;
}

/* Floating Large Star */
.rateAppModalTopStarImage {
    position: relative;
    top: 32px;
    width: 100px;
    z-index: 1;
}

/* Main Rating Card */
.rateAppModalContent {
    position: relative;
    background: #fff;
    border-radius: 20px;
    padding: 30px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*text-align: center;*/
    gap: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2);
}

/* Star Rating Section */
.rateAppModalContent .stars {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

/* Star Icons */
.rateAppModalContent .star {
    width: 56px;
    height: 56px;
    cursor: pointer;
}

/* Rate Button */
.rateAppModalContent .actionButton {
    padding: 12px 8px 12px;
    max-width: 188px;
    max-height: 48px;
}
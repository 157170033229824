.gameDetailsMediaImage {
  position: relative;
  overflow: hidden;
  height: 269px;
  background-size: cover;
  background-position: top;
}

.gameDetailsMediaInner {
  position: sticky;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 269px;
  margin-top: -36px;
  pointer-events: none;
}

.gameDetailsMediaContent {
  margin: auto;
  position: relative;
  pointer-events: auto;
}

.googleAuthButtonIcon {
  width: 24px;
  height: 24px;
  display: block;
}

.googleAuthButtonIconWrap {
  background-color: white;
  padding: 11px;
  position: relative;
  border-radius: 1px;
}

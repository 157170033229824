.offerAlert {
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.80) !important;
  box-shadow: -2px -4px 12px 0px rgba(255, 169, 6, 0.10), 2px 4px 12px 0px rgba(255, 169, 6, 0.10) !important;
  border-radius: 20px !important;
  border: 2px solid white !important;
  margin-bottom: 20px;
  padding-right: 20px !important;
}

.offerAlert .MuiAlert-message {
  width: 100%;
}

.offerAlert.showTime {
  border-radius: 20px 0px 20px 20px !important;
}

.offerAlertContent .actionButton {
  align-self: flex-end;
  width: max-content;
  padding: 6px 16px;
}

.offerAlertContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.offerAlertContent .title {
  color: #1F1F1F;
  font-weight: 500;
}

.offerAlertContent .children {
  color: #636874;
}

.offerAlertContent .complete {
  color: #039F76;
}

.actionContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.actionContainer .rewardsAndButtonConatiner {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
}

.actionContainer .rewardsAndButtonConatiner .actionButton {
  width: max-content;
  padding: 6px 16px;
}

/* Material UI css */
.MuiAlert-icon img {
  width: 48px;
  height: 48px;
  border-radius: 8px;
}
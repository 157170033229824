.favoritesGameCardBorder {
  padding: 4px;
  border-radius: 14px;
  background: linear-gradient(60deg, #ffcd47, #ffafc7);
  animation: animatedgradient 0.5s ease infinite;
  background-size: 300% 300%;
  box-shadow: 0 0 10px #ffcd47;
}

.favoritesGameCardExpired {
  display: flex;
  gap: 5px;
}

.favoritesGameCardExpired svg {
  height: 20px;
  width: 20px;
}

.favoritesGameCardImageWrap {
  padding: 1px;
  border-radius: 8px;
  background: var(--coin-gradient);
  min-width: 64px;
  max-width: 64px;
  height: 64px;
}

.favoritesGameCardEarnedText {
  color: green;
  position: absolute;
  z-index: -1;
  opacity: 1;
  transition: opacity 200ms;
}

.favoritesGameCardEarnedText.hidden {
  opacity: 0;
}

.favoritesGameCardImage {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 640px) {
  .favoritesGameCardEarned svg {
    height: 18px;
    width: 18px;
  }

  .favoritesGameCardExpired svg {
    height: 16px;
    width: 16px;
  }
}

@media (max-width: 380px) {
  .favoritesGameCardEarned svg {
    height: 16px;
    width: 16px;
  }

  .favoritesGameCardExpired svg {
    height: 14px;
    width: 14px;
  }
}
